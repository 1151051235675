.news-page {
    margin: 0 0 50px;
}

.link-list {
    list-style: none;
    padding: 0;
    font-size: 14px;
}

.link-list li {
    margin: 0 0 10px;
}

.info{
    /* padding-bottom: 10px; */
}

.link-list li a{
    text-decoration: none;
    transition: 0.3s linear color;
    display: block;
    background: #e7e9ee;
    border-bottom: 5px solid #4285F4;
    color: #111010;
    padding: 16px 22px;
}

.link-list li a:hover{
    color: #28406e;
}



.person {
    box-shadow: 0 10px 50px #a6d1ed33;
    border-radius: 15px;
}

.person .title-hold{
    background: #28406e;
}

.person .title {
    color: #fff;
    margin: 0;
    font-size: 12px;
    line-height: 1.5;
}

.person .img-hold {
    display: flex;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    /* background-color: red; */
}

.person .img-hold:after {
    content: '';
    font-size: 0;
    line-height: 0;
    display: block;
    min-height: 115px;
}

.person .name-txt {
    transition: .3s linear color;
    text-decoration: none;
    color: #28406e;
}

.person .name-txt:hover {
    color: #C60814;
}

@media (min-width: 480px) {}
@media (min-width: 768px) {
    .link-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px 10px;
        font-size: 16px;
    }

    .link-list li {
        padding: 0 10px;
        width: 33.33%;
        margin: 0 0 15px;
    }

    .news-page .news-holder {
        padding: 0 15px 15px;
    }

    .news-page .news-holder .nav-tabs {
        padding: 15px;
        font-size: 16px;
    }

    .news-page .news-holder .nav-tabs .nav-item{
        padding: 0 8px;
    }

    .news-page .news-holder .nav-tabs .nav-link {
        padding: 10px 20px;
    }

    .news-page .news-holder .tab-content {
        font-size: 15px;
        padding: 20px 30px;
    }
    
}